import React, { Component } from 'react';
import { Button, Card, CardBody, CardGroup, Col, Container, Input, InputGroup, InputGroupAddon, InputGroupText, Row, FormGroup, Label } from 'reactstrap';
import request from '../../services/request.js';
import api from '../../services/api.js';
import local from '../../services/local.js';
import helper from '../../services/helper';
import Loader from '../../controls/Loader';
import config from '../../services/config';
// import AccountKit from 'react-facebook-account-kit';
import { Trans } from 'react-i18next';
import i18next from 'i18next';

class VerifyLogin extends Component {
    constructor(props) {
        super(props);
        this.state = {
            otp: '',
            otpId: "",
            deviceId: "",
            loading: false,


        }
    }

    async changeShow(type) {
        if (type == 'inputOtp') {
            this.setState({ inputOtp: true });
            this.setState({ inputChangePass: false });
            this.setState({ inputEmail: false });

        }

    }


    async onLoginVerifyClick(e) {
        e.preventDefault();
        try {
            if (!this.state.otp)
                return helper.alert(i18next.t('Plese enter OTP'));

            let rs = await api.verifyLogin({
                otp: this.state.otp,
                otpId: await local.get('otpId'),
                deviceId: await local.get('deviceId')
            }, config.THIRD_PARTY_TOKEN);

            if (rs && rs.err === 400) {
                helper.alert(i18next.t(rs.message));
                return this.props.history.push(`/login`);
            }

            if (!rs || (rs && rs.err != 200)) {
                return helper.alert("Bad Request", rs.message || i18next.t('badRequest'), "warning");
            }
            if (rs.data) {
                local.set('otpId', "");
                local.set('session', rs.data.token);
                local.set('user', JSON.stringify(rs.data.userInfo));
                local.set('deviceId', rs.data.deviceId)
                local.set('code', rs.data.userInfo.isSubMerchant ? rs.data.userInfo.owner.code : rs.data.userInfo.code)
                local.set('phone', rs.data.userInfo.phone)
                local.set('tokenMcPt', rs.data.tokenMcPt && rs.data.tokenMcPt.token);
                // local.clear('otpId');
                this.props.history.push(`/dashboard`);

            }
            else {
                return this.props.history.push(`/verifyLogin`);


            }

        } catch (err) {

            helper.alert(err.message);
        }
        // return false;
    };
    async back(type) {
        this.setState({ username: "", captchaText: "", otp: '', password: '', confirmPassword: '' })
        this.loadCaptcha();
        this.changeShow('inputEmail');

    }
    async login(e) {
        return this.props.history.push(`/login`);
    }


    render() {
        if (this.state.loading) return <Loader />
        return (
            <div className="app flex-row align-items-center">
                <Container>
                    <Row className="justify-content-center">
                        <Col md="6">
                            <CardGroup>
                                <Card className="p-4">
                                    <CardBody>
                                        <Row>

                                            <Col md={12}>
                                                <img src='assets/img/otp.png' className='icon-logo' alt='avatar' />
                                            </Col>


                                            <Col md={12}>
                                                <p className="title-verify">{i18next.t('Verification')}
                                                </p>
                                            </Col>


                                        </Row>
                                        <Row>

                                            <Col md={12}>
                                                <p className="center">{i18next.t('sendOTP')}</p>
                                            </Col>

                                        </Row>
                                        <Row>
                                            <Col md={12}>


                                                {

                                                    <form onSubmit={this.onLoginVerifyClick.bind(this)} autoComplete='off'>
                                                        <InputGroup className="mb-3">
                                                            <InputGroupAddon addonType="prepend">
                                                                <InputGroupText>
                                                                    <span >{i18next.t('OTP')}</span>
                                                                </InputGroupText>
                                                            </InputGroupAddon>
                                                            <Input type="text" placeholder={i18next.t('* * * * * *')} value={this.state.otp} onChange={evt => this.setState({ otp: evt.target.value })} />
                                                        </InputGroup>

                                                        <Row>
                                                            <Col xs="6" md="6" lg="6" className="text-right">
                                                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-arrow-left"><path d="m12 19-7-7 7-7" /><path d="M19 12H5" /></svg>
                                                                <Button color="danger" className="px-0" type='button' block onClick={() => { this.login() }} >{i18next.t('GoBackToLogin')}</Button>
                                                            </Col>

                                                            <Col xs="6" md="6" lg="6">
                                                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-arrow-right"><path d="M5 12h14" /><path d="m12 5 7 7-7 7" /></svg>
                                                                <Button color="primary" type='submit' className="color-logo-ayapay" block>{i18next.t('Validate OTP')}</Button>
                                                            </Col>
                                                        </Row>

                                                    </form>

                                                }


                                            </Col>

                                        </Row>




                                    </CardBody>
                                </Card>
                            </CardGroup>
                        </Col>
                    </Row>
                </Container>
            </div>
        );
    }
}
async function validateForm(data, type) {

    if (!data.password) return helper.alert(i18next.t("Please enter newpassword"))
    if (!helper.validateInput(data.password, config.REGEX_PASSWORD))
        return helper.alert(i18next.t("Username invalid or too easy please using username different"))
    if (!data.confirmPassword) return helper.alert(i18next.t("Please enter confirm password"));
    if (data.password != data.confirmPassword) return helper.alert(i18next.t("Confirm password in correct"));

    return true


}
export default VerifyLogin;
