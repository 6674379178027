import React, { Component } from 'react';
import { HashRouter, Route, Switch } from 'react-router-dom';
import './App.css';
// Styles
// CoreUI Icons Set
import '@coreui/icons/css/coreui-icons.min.css';
// Import Flag Icons Set
import 'flag-icon-css/css/flag-icon.min.css';
// Import Font Awesome Icons Set
import 'font-awesome/css/font-awesome.min.css';
// Import Simple Line Icons Set
import 'simple-line-icons/css/simple-line-icons.css';
// Import Main styles for this application
import './scss/style.css'
import "react-table/react-table.css";
import 'react-table-hoc-fixed-columns/lib/styles.css';
import 'react-image-lightbox/style.css';
// import css for react checkboc-tree
import 'react-checkbox-tree/lib/react-checkbox-tree.css';


import "react-datepicker/dist/react-datepicker.css";
import './index.css';
import './scss/_custom.scss'
// Containers
import { DefaultLayout } from './containers';
// Pages
import Login from './views/Login/Login';
import MerchantLogin from './views/Login/MerchantLogin';
import ForgotPass from './views/ForgotPass/ForgotPass';
import VerifyLogin from './views/VerifyLogin/VerifyLogin';

// import { renderRoutes } from 'react-router-config';

class App extends Component {
  render() {
    return (
      <HashRouter>
        <Switch>
          {/* <Route exact path="/login" name="Login Page" component={Login} /> */}
          <Route exact path="/login" name="Login Page" component={MerchantLogin} />

          <Route exact path="/forgotPass" name="ForgotPass Page" component={ForgotPass} />
          <Route exact path="/verifyLogin" name="ForgotPass Page" component={VerifyLogin} />

          <Route path="/" name="Home" component={DefaultLayout} />
        </Switch>
      </HashRouter>
    );
  }
}

export default App;
