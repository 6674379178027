import React from 'react';
import Loadable from 'react-loadable'

function Loading() {
    return <div>Loading...</div>;
}

/*const Dashboard = Loadable({
    loader: () => import('./views/Dashboard'),
    loading: Loading
});*/
const Profile = Loadable({
    loader: () => import('./views/User/Profile'),
    loading: Loading
});
const PageEditor = Loadable({
    loader: () => import('./views/PageManager/PageEditor'),
    loading: Loading
});
const FormViewer = Loadable({
    loader: () => import('./views/Form/FormViewer'),
    loading: Loading
});
const ListViewer = Loadable({
    loader: () => import('./views/List/ListViewer'),
    loading: Loading
});
const VoucherApprove = Loadable({
    loader: () => import('./views/VoucherApprove'),
    loading: Loading
});
const Checkout = Loadable({
    loader: () => import('./views/Checkout'),
    loading: Loading
});

const CCSearchPhone = Loadable({
    loader: () => import('./views/CC/SearchPhone'),
    loading: Loading
});


const ForgotPass = Loadable({
    loader: () => import('./views/ForgotPass/ForgotPass'),
    loading: Loading
});
const VerifyLogin = Loadable({
    loader: () => import('./views/VerifyLogin/VerifyLogin'),
    loading: Loading
});



// const VerifyLogin = React.lazy(() => import('./views/VerifyLogin/VerifyLogin'));

const Dashboard = React.lazy(() => import('./views/Dashboards/Dashboards'));
const Merchants = React.lazy(() => import('./views/Merchants/Merchants'));
const SubMerchants = React.lazy(() => import('./views/SubMerchants/SubMerchants'));
const AddDevice = React.lazy(() => import('./views/SubMerchants/AddDevice'));

const Shops = React.lazy(() => import('./views/Shops/Shops'));
const ShopCreate = React.lazy(() => import('./views/Shops/ShopCreate'));
const ShopUpdate = React.lazy(() => import('./views/Shops/ShopUpdate'));
const ShopDetail = React.lazy(() => import('./views/Shops/ShopDetail'));
const SubmerchantCreate = React.lazy(() => import('./views/SubMerchants/SubmerchantCreate'));
const SubmerchantUpdate = React.lazy(() => import('./views/SubMerchants/SubmerchantUpdate'));
const SubmerchantDetail = React.lazy(() => import('./views/SubMerchants/SubmerchantDetail'));
const TransactionHistory = React.lazy(() => import('./views/TransactionHistory/TransactionHistory'));
const TransactionDetail = React.lazy(() => import('./views/TransactionHistory/TransactionDetail'));
const UserRoles = React.lazy(() => import('./views/UserRoles/UserRoles'));
const UserPermission = React.lazy(() => import('./views/UserRoles/UserPermission'));
const Permissions = React.lazy(() => import('./views/UserRoles/Permissions'));
const CreateUserRole = React.lazy(() => import('./views/UserRoles/CreateUserRole'));

const APIKeys = React.lazy(() => import('./views/ApiKeys/ApiKeys'));
const CreateApiKey = React.lazy(() => import('./views/ApiKeys/CreateApiKey'));
const DetailApiKey = React.lazy(() => import('./views/ApiKeys/DetailApiKey'));


const EarnPointHistory = React.lazy(() => import('./views/Point/EarnPointHistory'));
const SettlementPointHistory = React.lazy(() => import('./views/Point/SettlementPointHistory'));
const SettlementPoint = React.lazy(() => import('./views/Point/SettlementPoint'));

const ExportReportFiles = React.lazy(() => import('./views/ExportReportFiles/ExportReportFileList'));


const routes = [
    {
        path: '/dashboard',
        name: 'Dashboard',
        component: Dashboard
    },
    { path: '/transaction-history', name: 'Transaction History', component: TransactionHistory },
    { path: '/transaction-detail/:id', name: 'Transaction Detail', component: TransactionDetail },

    { path: '/earn-point-history', name: 'Earn Point History', component: EarnPointHistory },
    { path: '/settlement-point', name: 'Settlement Point History', component: SettlementPointHistory },
    { path: '/createSettlementPoint', name: 'Settlement Point', component: SettlementPoint },

    { path: '/merchants', name: 'Merchants', component: Merchants },
    { path: '/api-keys', name: 'API Keys', component: APIKeys },
    { path: '/api-key/detail/:id', name: 'Detail API Key', component: DetailApiKey },
    { path: '/api-key/create', name: 'Create API Keys', component: CreateApiKey },

    { path: '/submerchants', name: 'Employee [SubMerchants]', component: SubMerchants },
    { path: '/device/create/:id', name: 'Create Device', component: AddDevice },
    { path: '/shops', name: 'Shops', component: Shops },
    { path: '/shop/detail/:id', name: 'Shop Detail', component: ShopDetail },
    { path: '/shop/create', name: 'Shop Create', component: ShopCreate },
    { path: '/shop/edit/:id', name: 'Shop Update', component: ShopUpdate },
    { path: '/shop/export', name: 'Shop Export', component: ShopCreate },
    { path: '/submerchant/create', name: 'Employee [Submerchant] Create', component: SubmerchantCreate },
    { path: '/submerchant/edit/:id', name: 'Employee [Submerchant] Update', component: SubmerchantUpdate },
    { path: '/submerchant/detail/:id', name: 'Employee [Submerchant] Detail', component: SubmerchantDetail },
    { path: '/user-roles', name: 'Roles & Permissions', component: UserRoles },
    { path: '/role/create', name: 'Create User Role', component: CreateUserRole },

    { path: '/exportedReports', name: 'Export Report File List', component: ExportReportFiles },



    { path: '/permission', exact: true, name: 'Permission', component: UserRoles },
    { path: '/permission/user-roles', name: 'User Roles', component: UserRoles },
    { path: '/user-permission/:id', name: 'User Permission', component: UserPermission },
    { path: '/permissions', name: 'Permissions', component: Permissions },
    { path: '/verifyLogin', name: 'Verify Login', component: VerifyLogin },

    {
        path: '/pageEditor',
        name: 'Quản lý trang',
        component: PageEditor
    }, {
        path: '/form',
        name: 'Trang',
        component: FormViewer
    }, {
        path: '/list',
        name: 'Trang',
        component: ListViewer
    }, {
        path: '/profile',
        name: 'Profile',
        component: Profile
    },
    {
        path: '/voucherApprove',
        name: 'Duyệt điều kiện voucher',
        component: VoucherApprove
    }, {
        path: '/checkout',
        name: 'Duyệt điều kiện voucher',
        component: Checkout
    },
    {
        path: '/searchphone',
        name: 'Tìm kiếm số điện thoại',
        component: CCSearchPhone
    },
    {
        path: '/forgotPass',
        name: 'ForgotPass',
        component: ForgotPass
    }
];

export default routes;
