import React, { Component, Suspense } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import * as router from 'react-router-dom';
import { Container } from 'reactstrap';
import { connect } from 'react-redux'

import {
  AppAside,
  AppFooter,
  AppHeader,
  AppSidebar,
  AppSidebarFooter,
  AppSidebarForm,
  AppSidebarHeader,
  AppSidebarMinimizer,
  AppSidebarNav,
  AppBreadcrumb2 as AppBreadcrumb,
} from '@coreui/react';
// sidebar nav config
import navigation from '../../_nav';
// routes config
import routes from '../../routes';

import local from '../../services/local';
import api from '../../services/api';
import _ from 'lodash';
import i18next from 'i18next';


const DefaultAside = React.lazy(() => import('./DefaultAside'));
const DefaultFooter = React.lazy(() => import('./DefaultFooter'));
const DefaultHeader = React.lazy(() => import('./DefaultHeader'));

class DefaultLayout extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      nav: []
    }
  }



  async componentDidMount() {
    if(this.props.location.pathname === '/'){
      let phone = local.get('phone');
      let code = local.get('code');
      let deviceId = local.get('deviceId');
      local.clear();
      local.set('deviceId', deviceId)
      local.set('code', code)
      local.set('phone', phone)
    }
    /*window.onbeforeunload = function (e) {
        window.onunload = function () {
              let phone = local.get('phone');
              let code = local.get('code');
              let deviceId = local.get('deviceId');
              local.clear();
              local.set('deviceId', deviceId)
              local.set('code', code)
              local.set('phone', phone)
        }
        return '';
    };*/

    try {
      var session = await local.get('session')
      if (!session || session == 'undefined') {
        if (this.props.location.pathname == "/forgotPass") {
          return this.props.history.push(`/forgotPass`);
        }
        if (this.props.location.pathname == "/verifyLogin") {
          return this.props.history.push(`/verifyLogin`);

        }
        this.props.history.replace('/login');

      } else {
        let userInfo = local.get('user');
        var permitNavList = navigation.items.filter(function (o1) {
            return userInfo.webPermissions.some(function (o2) {
                if( ["dashboard", "profile"].includes(o1.action)){
                  //always show menu list
                  return o1;
                }else{
                    if((userInfo.type !== "thirdparty" && o1.action === "findapikeys")){
                      // hide apikey menu when type is not thirdparty
                    }else{
                      return  o1.action === o2.action; // return the ones with equal id
                    }
                }
            });
        });

        this.setState({ nav: {items: permitNavList} });

        this.setState({ loading: false });

        /*let meta = await api.getMeta();
        meta.pages.map(page => {
          if (!Array.isArray(page.buttons)) page.buttons = [];
          if (page.form && page.form.schema && page.form.schema.properties) {
            for (var i in page.form.schema.properties) {
              page.form.schema.properties[i].pageId = page.id;
            }
            for (i in page.schema) {
              page.schema[i].pageId = page.id;
            }
          }
          page.schema = eval(page.schema)
          for (var i in page.schema) {
            // console.log(page.schema)
            page.schema[i].pageId = page.id;
          }
          return null;
        })
        local.set('meta', JSON.stringify(meta));
        let userInfo = local.get('user');
        let nav = this.calculateNav(meta.menus, userInfo.role);
        */

        // this.setState({ loading: false, nav: navigation });
      }


    } catch (err) {
      console.log('loi roi , err here', err)
      this.props.history.replace('/login');
    }
  }
  /*calculateNav(allMenus, role) {
    let menus = [];
    allMenus.map(m => {
      m.isOpen = false;
      if (!m.roles) {
        return menus.push(m);
      }
      if (m.roles.length === 0) {
        return menus.push(m);
      }
      if (_.includes(m.roles, role)) {
        return menus.push(m);
      }
      return null;
    });

    let items = [];
    menus.map(m => {
      if (!m.parent) {
        m.isOpen = false;
        if (!m.url) m.url = 'url'
        items.push(m);
      }
      return null;
    })
    menus.map(m => {
      if (!m.parent) return null;
      for (var i = 0; i < items.length; i++) {
        if (items[i].id === m.parent) {
          if (!items[i].children) items[i].children = [];
          items[i].children.push(m);
        }
      }
      return null;
    });
    return { items };
  }*/

  loading = () => <div className="animated fadeIn pt-1 text-center">Loading...</div>

  signOut(e) {
    e.preventDefault()
    this.props.history.push('/login')
  }

  render() {
    if (this.state.loading) return (<p>{i18next.t('PROCESSING')}</p>)

    return (
      <div className="app">
        <AppHeader fixed>
          <Suspense fallback={this.loading()}>
            <DefaultHeader onLogout={e => this.signOut(e)} />
          </Suspense>
        </AppHeader>
        <div className="app-body">
          <AppSidebar fixed display="lg">
            <AppSidebarHeader />
            <AppSidebarForm />
            <Suspense>
              {/*<AppSidebarNav navConfig={this.state.nav} location={this.props.location} />*/}
              <AppSidebarNav navConfig={this.state.nav} router={router} />
            </Suspense>
            <AppSidebarFooter />
            <AppSidebarMinimizer />
          </AppSidebar>
          <main className="main">
            <AppBreadcrumb appRoutes={routes} router={router} />
            <Container fluid>
              <Suspense fallback={this.loading()}>
                <Switch>
                  {routes.map((route, idx) => {
                    return route.component ? (<Route key={idx} path={route.path} exact={route.exact} name={route.name} render={props => (
                      <route.component {...props} />
                    )} />)
                      : (null);
                  },
                  )}
                  <Redirect from="/" to="/dashboard" />
                </Switch>
              </Suspense>
            </Container>
          </main>
          <AppAside fixed>
            <Suspense fallback={this.loading()}>
              <DefaultAside />
            </Suspense>
          </AppAside>
        </div>
        <AppFooter>
          <Suspense fallback={this.loading()}>
            <DefaultFooter />
          </Suspense>
        </AppFooter>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return { userinfo: state.userinfo }
}
export default connect(mapStateToProps)(DefaultLayout);

// export default DefaultLayout;
