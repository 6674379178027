import React, { Component } from 'react';
import publicIp from 'public-ip';
import { Button, Card, CardBody, CardGroup, Col, Container, Input, InputGroup, InputGroupAddon, InputGroupText, Row } from 'reactstrap';
import request from '../../services/request.js';
import api from '../../services/api.js';
import local from '../../services/local.js';
import helper from '../../services/helper';
import Loader from '../../controls/Loader';
import config from '../../services/config';
// import AccountKit from 'react-facebook-account-kit';
import { Trans } from 'react-i18next';
import i18next from 'i18next';
import './custom.css';
import {
    BrowserView,
    MobileView,
    isBrowser,
    isMobile,
    osVersion,
    osName,
    browserVersion,
    browserName,
    deviceType,
    deviceDetect,
    getUA
} from "react-device-detect";

class MerchantLogin extends Component {
    constructor(props) {
        super(props);
        this.state = {
            code: "",
            username: '',
            password: '',
            loading: true,
            captchaId: 0,
            captchaText: '',
            captcha: null,
            deviceId: "",
            loading: false,
            // accountKitToken: ''
        }
    }
    componentDidMount() {
        let code = local.get('code') || "";
        let phone = local.get('phone') || "";
        let deviceId = local.get('deviceId') || "";
        local.clear();
        local.set('deviceId', deviceId);
        local.set('code', code);
        local.set('phone', phone);
        this.setState({ code: code, username: phone });
        this.loadCaptcha();
    }
    async loadCaptcha() {
        //load captcha
        let captInfo = await api.getCaptcha();
        this.setState({ loading: false, captchaId: captInfo.id, captcha: captInfo.data })
    }
    async onLoginClick(e) {
        this.setState({ loading: true });
        e.preventDefault();
        try {
            if (!this.state.code){
              this.setState({loading: false});
              return helper.alert(i18next.t('Plese enter Owner ID.'));
            }
            if (!this.state.username) {
                this.setState({ loading: false });
                return helper.alert(i18next.t('Plese enter username'));
            }
            if (!this.state.password) {
                this.setState({ loading: false });
                return helper.alert(i18next.t('Plese enter password'));
            }
            if (!this.state.captchaText) {
                this.setState({ loading: false });
                return helper.alert(i18next.t('Plese enter capcha'));
            }
            // let ip = await publicIp.v4();
            let ip = "127.0.0.1";
            // let rs = await api.thirdPartyLogin({ username: this.state.username, code: this.state.code, password: this.state.password, captchaId: this.state.captchaId, captchaText: this.state.captchaText, accountKitToken: this.state.accountKitToken }, config.THIRD_PARTY_TOKEN);
            let rs = await api.login({
                username: this.state.username, code: this.state.code, password: this.state.password, captchaId: this.state.captchaId, captchaText: this.state.captchaText,
                osVersion: osVersion,
                ip,
                os: osName,
                browserVersion: browserVersion,
                browser: browserName,
                deviceId: await local.get('deviceId')
            }, config.THIRD_PARTY_TOKEN);

            if (!rs || (rs && rs.err != 200)){
                this.loadCaptcha();
                this.setState({loading: false});
                return helper.alert("Bad Request",rs.message || i18next.t('badRequest'), "warning");
            }
            if (rs.data && !rs.data.otp) {
                local.set('session', rs.data.token);
                local.set('user', JSON.stringify(rs.data.userInfo));
                local.set('deviceId', rs.data.deviceId);
                local.set('code', this.state.code);
                local.set('phone', rs.data.userInfo.phone);
                local.set('tokenMcPt', rs.data.tokenMcPt && rs.data.tokenMcPt.token);

                this.setState({ loading: false });
                this.props.history.push(`/dashboard`);

            }
            else {
                if (rs.data.deviceId) {
                    local.set('deviceId', rs.data.deviceId);
                }
                if (rs.data.otpId) {
                    local.set('otpId', rs.data.otpId);
                }

                this.setState({ loading: false });
                return this.props.history.push(`/verifyLogin`);
            }

        } catch (err) {
            console.log("loadCaptcha", err)
            this.loadCaptcha();
            helper.alert(err.message);
            this.setState({ loading: false });
        }
        // return false;
    };

    async resetPass(e) {
        return this.props.history.push(`/forgotPass`);
    }


    async handleAccountKitResponse(result) {
        console.log('on token', result);
        this.setState({ accountKitToken: result.code });
    }
    render() {
        if (this.state.loading) return <Loader />
        return (
            <div className="app flex-row align-items-center">
                <Container>
                    <Row className="justify-content-center">
                        <Col md="6">
                            <CardGroup>
                                <Card className="p-4">
                                    <CardBody>
                                        <Row>

                                            <Col md={12}>
                                                <img src='assets/img/ayapay.png' className='icon-logo' alt='avatar' />
                                                <p className="title-web">AYAPay Merchant
                                                </p>
                                            </Col>
                                        </Row>


                                        <form onSubmit={this.onLoginClick.bind(this)} autoComplete='off'>
                                            <InputGroup className="mb-3">
                                                <InputGroupAddon addonType="prepend">
                                                    <InputGroupText>
                                                        <i className="icon-user"></i>
                                                    </InputGroupText>
                                                </InputGroupAddon>
                                                <Input type="text" placeholder={i18next.t('userId')} value={this.state.code} onChange={evt => this.setState({ code: evt.target.value })} />
                                            </InputGroup>
                                            <InputGroup className="mb-3">
                                                <InputGroupAddon addonType="prepend">
                                                    <InputGroupText>
                                                        <i className="icon-phone"></i>
                                                    </InputGroupText>
                                                </InputGroupAddon>
                                                <Input type="text" placeholder={i18next.t('phone')} value={this.state.username} onChange={evt => this.setState({ username: evt.target.value })} />
                                            </InputGroup>
                                            <InputGroup className="mb-3">
                                                <InputGroupAddon addonType="prepend">
                                                    <InputGroupText>
                                                        <i className="icon-lock"></i>
                                                    </InputGroupText>
                                                </InputGroupAddon>
                                                <Input type="password" placeholder={i18next.t('password')} value={this.state.password} autoComplete='off' onChange={evt => this.setState({ password: evt.target.value })} />
                                            </InputGroup>

                                            <Row className='mb-4'>
                                                <Col md={6}>
                                                    <div className='captcha' dangerouslySetInnerHTML={{ __html: this.state.captcha }}></div>
                                                </Col>
                                                <Col md={6}>
                                                    <InputGroup className="mb-4">
                                                        {/*<InputGroupAddon addonType="prepend">*/}
                                                        <Input type="text" placeholder={i18next.t('Please Enter Captcha')} value={this.state.captchaText} onChange={evt => this.setState({ captchaText: evt.target.value })} />
                                                        {/*<InputGroupAddon addonType="prepend">*/}
                                                        <Button color='light' type='button' className="btn-square" onClick={() => {
                                                            this.loadCaptcha();
                                                        }}>
                                                            <i className='fa fa-refresh' /></Button>
                                                        {/*</InputGroupAddon>*/}
                                                        {/*</InputGroupAddon>*/}
                                                    </InputGroup>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col xs="12">
                                                    <Button disabled={this.state.loading} color="primary" type='submit' className="btn-square btn btn-primary" block>{i18next.t('login')}</Button>
                                                </Col>
                                                <Col xs="6" className="text-right hide">
                                                    <Button color="link" className="px-0" type='button' block onClick={() => { this.resetPass() }} >{i18next.t('Forgot password?')}</Button>
                                                </Col>
                                            </Row>
                                        </form>
                                    </CardBody>
                                </Card>
                            </CardGroup>
                        </Col>
                    </Row>
                </Container>
            </div >
        );
    }
}

export default MerchantLogin;
