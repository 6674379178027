export default {
  items: [
    {
      name: 'Dashboards',
      url: '/dashboard',
      icon: 'icon-speedometer',
      action: 'dashboard'
    },
    {
      name: 'Transaction History',
      url: '/transaction-history',
      icon: 'icon-chart',
      action:"findtransactions"
    },

    {
      name: 'Merchant Information',
      url: '/merchants',
      icon: 'fa fa-television',
      action: 'profile'
    },
    {
      name: 'API Keys',
      url: '/api-keys',
      icon: 'fa fa-cong',
      action: 'findApiKeys'
    },
    {
      name: 'Shop Management',
      url: '/shops',
      icon: 'fa fa-building-o',
      action: "findshops"
    },
    {
      name: 'Employee Management',
      url: '/submerchants',
      icon: 'icon-people',
      action:"findsubmerchants"
    },
    {
      name: 'Earning Points',
      url: '/earn-point-history',
      icon: 'icon-present',
      action: 'earnpointhistory'
    },
    {
      name: 'Points Settlement',
      url: '/settlement-point',
      icon: 'icon-refresh',
      action: 'settlementpointhistory'
    },
    {
      name: 'Roles & Permission',
      url: '/user-roles',
      icon: 'icon-lock-open',
      action: 'findclientroles'
    },

    {
      name: 'API Keys',
      url: '/api-keys',
      icon: 'icon-lock-open',
      action: 'findapikeys'
    },
    {
      name: 'Exports Report File  List',
      url: '/exportedReports',
      icon: 'icon-chart',
      action: 'exportedreports'
    },

    /*{
      name: 'Permissions',
      url: '/permissions',
      icon: 'icon-lock',
    },*/

  ]
};
